// src/data/eventsData.js
export const events = [
    {
      title: 'Community Outreach Program',
      type: 'Workshop',
      location: 'Lagos, Nigeria',
      date: 'October 20, 2024',
      description: 'A workshop focused on talent discovery for young people.',
    },
    {
      title: 'Talent Development Summit',
      type: 'Conference',
      location: 'Nairobi, Kenya',
      date: 'November 5, 2024',
      description: 'Summit on nurturing and monetizing talents.',
    },
    {
      title: 'Educational Fundraiser',
      type: 'Fundraiser',
      location: 'Accra, Ghana',
      date: 'December 10, 2024',
      description: 'Raising funds for the education of underserved children.',
    },
    {
      title: 'Art Exhibition',
      type: 'Art Exhibition',
      location: 'Nigeria and Kenya',
      date: 'June, 2026',
      description: 'Art Exibition.',
    },
    // Add more events as needed
  ];
  