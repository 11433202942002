import React from 'react';
import HeroSection from '../components/HeroSection';
import love from '../images/love.jpg';
import charity from '../images/charity.webp'

function Donate() {
  return (
    <>
    <div>
      <HeroSection title="Support Us"
      backgroundImage={charity}/>
    </div>
    <main className="container mx-auto py-10 px-4 text-center">
    <div className="container mx-auto p-4">
      <section className="mx-auto flex flex-col md:flex-row my-4 items-stretch md:space-x-8">
        <div className="text-center flex-1 mt-4">
        <h2 className="text-3xl mb-3 font-bold text-center">
        Join Us in empowering Africa’s youth!
        </h2>
          <p className='text-xl text-justify'>
          Empowering Africa’s youth is at the heart of our mission. By donating to the Prince Child Development Foundation, you can help us provide education, mentorship, and opportunities for young talents in rural areas.
            <br />You can make a one-time contribution or inquire about setting up monthly donations by reaching out to us at {" "}
            <strong>
              <a
                className="text-black"
                href="mailto:admin@pcdfafrica.org"
              >
                admin@pcdfafrica.org
              </a>
            </strong>
          </p>
          <p className="mt-2 text-lg">
          For further information on how you can support our cause, please call: +2349094640954 | +2348125229157
          </p>
          <p className="mt-2 font-bold">The Main Ways To Donate Are:</p>
        </div>

        <div className="text-center flex-1 my-8">
          <img
            src={love}
            alt="Donation"
            className="w-full max-w-lg mx-auto rounded-md"
          />
        </div>
      </section>

      <section className="mb-8 mx-auto flex flex-col md:flex-row items-stretch md:space-x-8">
      <div className="my-4 flex-1">
  <h2 className="text-4xl font-bold">1. Online Donations</h2>
  <p className='text-xl my-4'>You can make donations online via our Flutterwave Account by clicking on the button below:</p>
  <div className="mt-2">
    <a
      className="inline-block text-white text-3xl font-bold py-2 px-4 rounded-2xl flutterwave-button"
      href="https://flutterwave.com/donate/l8kv0ba31gpk"
    >
      Flutterwave
    </a>
  </div>
</div>

        <div className="my-4 flex-1">
          <h2 className="text-4xl font-bold">2. Local Bank Deposits & Transfers</h2>
          <p className='text-xl'>You can make donations through direct transfers to our bank account.</p>
          <p className='text-xl'>Please find details below:</p>
          <div className="my-4 flex flex-col md:flex-row items-center">
  <img
    src="https://voiceofcarefoundation.org/wp-content/uploads/2024/08/Wema_Bank_Plc.jpg"
    alt="Bank Logo"
    className="w-64 mx-auto md:w-1/2 flex-shrink-0"
  />
  <div className="flex-1 md:ml-4">
    <h2 className="text-lg font-bold">Prince Child Development Foundation</h2>
    <h2 className="text-lg font-bold">Account Number: 0125021423</h2>
  </div>
</div>
          
        </div>
      </section>

      
    </div>
    </main>
    </>
  );
}

export default Donate;
