import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import pcdflogo from '../../src/images/pcdflogo2.png';

function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="p-4 text-black">
      <nav className="container mx-auto flex justify-between items-center">
        <img src={pcdflogo} className="w-25 h-16" alt="logo" />
        
        {/* Desktop Menu */}
        <ul className="hidden md:flex space-x-4 font-bold gap-10">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about">About Us</Link></li>
          <li><Link to="/projects">Projects</Link></li>
          <li><Link to="/contact">Contact Us</Link></li>
          <li><Link to="/donate" className="bg-red-500 text-white py-2 px-5 rounded-full">Donate</Link></li>
        </ul>
        
        {/* Mobile Menu Button */}
        <button 
          className="md:hidden text-3xl"
          onClick={toggleMobileMenu}
        >
          &#9776; {/* Hamburger icon */}
        </button>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <ul className="absolute top-16 left-0 w-full bg-white flex flex-col items-center space-y-4 py-4 font-bold md:hidden z-10 shadow-lg">
            <li><Link to="/" onClick={toggleMobileMenu}>Home</Link></li>
            <li><Link to="/about" onClick={toggleMobileMenu}>About Us</Link></li>
            <li><Link to="/projects" onClick={toggleMobileMenu}>Projects</Link></li>
            <li><Link to="/contact" onClick={toggleMobileMenu}>Contact Us</Link></li>
            <li><Link to="/donate" className="bg-red-500 text-white py-2 px-5 rounded-full" onClick={toggleMobileMenu}>Donate</Link></li>
          </ul>
        )}
      </nav>
    </header>
  );
}

export default Header;

