import React from 'react';

const TheoryOfChange = () => {
  return (
    <div className="p-5 md:p-10 bg-gradient-to-br from-blue-500 to-red-500 text-white">
      <div className="text-center md:px-40 py-8">
        <h2 className="font-bold text-3xl md:text-4xl py-3">Theory of Change</h2>
        <p className="text-lg md:text-xl leading-relaxed">
          Prince Child Development Foundation's (PCDF) theory of change is grounded in the belief that by identifying and nurturing young talents in rural Africa and by providing them with educational and professional opportunities, you can empower these individuals to achieve personal and economic success. This empowerment transforms their lives and drives significant social and economic change within their communities, ultimately contributing to the broader development of Africa.
        </p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-6 mt-8">
        {[
          {
            title: 'Identification of Talents',
            assumption: 'There is untapped potential in rural areas, particularly among young people who lack access to resources and opportunities.',
            action: 'PCDF focuses on discovering these hidden talents by conducting outreach and talent identification initiatives.'
          },
          {
            title: 'Nurturing and Education',
            assumption: 'With the right education, resources, and mentorship, these young talents can develop their skills to a level where they can achieve success.',
            action: 'PCDF provides comprehensive training and mentorship tailored to each participant\'s specific talents, enabling them to excel in their chosen fields.'
          },
          {
            title: 'Empowerment and Economic Opportunity',
            assumption: 'Once equipped with skills and knowledge, these young talents can enter the global economy, whether through entrepreneurship, employment, or monetizing their talents.',
            action: 'PCDF creates platforms and opportunities for participants to showcase and monetize their work, providing them with economic independence.'
          },
          {
            title: 'Social Change and Community Development',
            assumption: 'As these individuals succeed, they will inspire and uplift their communities, contributing to broader social and economic development.',
            action: 'The success stories of these young talents serve as catalysts for change, encouraging others in the community to pursue their potential, thus fostering a cycle of continuous development.'
          },
          {
            title: 'Scalability and Sustainability',
            assumption: 'The model is scalable and can be adapted to different African regions, amplifying its impact.',
            action: 'PCDF plans to expand its initiatives across the continent, adapting to different cultural contexts and integrating digital tools to reach a wider audience.'
          },
        ].map((element, index) => (
          <div
            key={index}
            className="p-6 rounded-lg bg-white text-black shadow-lg transform transition-transform duration-300 hover:scale-105"
          >
            <h3 className="font-bold text-xl mb-2 text-blue-600">{element.title}</h3>
            <p className="text-gray-700">
              <span className="font-semibold text-red-500">Assumption: </span>
              {element.assumption}
            </p>
            <p className="mt-2 text-gray-700">
              <span className="font-semibold text-blue-500">Action: </span>
              {element.action}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TheoryOfChange;

