import React from 'react';
import { events } from '../data/eventsData';

function EventsPage() {
  return (
    <div className="container mx-auto py-10 px-4">
      <h1 className="text-4xl font-bold mb-8">Upcoming Events</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {events.map((event, index) => (
          <div key={index} className="p-6 border rounded-md shadow-md hover:shadow-lg transition-shadow">
            <h2 className="text-2xl font-bold mb-2">{event.title}</h2>
            <p className="text-sm text-gray-600 mb-2">{event.type}</p>
            <p className="text-sm mb-2">Location: {event.location}</p>
            <p className="text-sm mb-4">Date: {event.date}</p>
            <p className="mb-4">{event.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default EventsPage;