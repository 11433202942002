import React from 'react';
import Ourstory from '../components/Ourstory';
import Mission from '../components/Mission';
import Ourvalue from './../components/Ourvalue';
import Team from './../components/Team';
import TheoryOfChange from '../components/TheoryOfChange';
// import StrategicGoals from '../components/StrategicGoals';

function About() {
  return (
    <main className=" py-10 ">
      <section className="text-center">
        <h1 className="text-4xl font-bold mb-4 md:text-5xl">About Us</h1>
        <p className="text-lg">
        Prince Child Development Foundation (PCDF) is a volunteer-based NGO dedicated to empowering Africa's youth. We provide education and resources for talented individuals in the arts, communication, and entrepreneurship to succeed and drive social change in their communities.
        </p>
      </section>
      <div className="">
        <Ourstory/>
      </div>
      <div className="">
        <Mission/>
      </div>
      <div className="">
        <Ourvalue/>
      </div>
      <div className=''>
      <TheoryOfChange/>
      </div>
      <div>
      {/* <StrategicGoals/> */}
      </div>
      <div className="">
        <Team/>
      </div>
    </main>
  );
}

export default About;