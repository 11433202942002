import React from 'react';
import Ourstory1 from "../images/pcdfteam2.jpg";
import Ourstory2 from "../images/pcdfteam1.jpg";

const Ourstory = () => {
  const altText = "Prince Child Development Foundation activities";

  return (
    <section className="px-4 py-8 lg:py-16 lg:px-20 bg-gray-50">
      {/* Section 1 */}
      <div className="flex flex-col xl:flex-row gap-8 items-center">
        {/* Image Section */}
        <div className="xl:w-1/2">
          <img
            className="rounded-2xl w-full object-cover xl:h-[450px] xl:mb-4"
            src={Ourstory1}
            alt={altText}
          />
        </div>

        {/* Text Section */}
        <div className="xl:w-1/2 text-center xl:text-left space-y-4">
          <h2 className="text-4xl font-bold text-red-500">Our Story</h2>
          <p className="text-lg text-gray-700 leading-relaxed">
            Prince Child Development Foundation (PCDF) is a volunteer-based registered NGO dedicated to unlocking the vast potential within Africa’s youthful population.
            Recognizing that not every young person is destined for traditional academic success, PCDF seeks to create opportunities for those with exceptional talents in the arts, communication, and entrepreneurship.
            Our mission is to provide these young talents with the education, resources, and platforms they need to succeed in the global economy while also empowering them to drive social change within their communities.
          </p>
          <p className="text-lg text-gray-700 leading-relaxed">
            Our approach involves a model of discovering, developing, and deploying (3D model) these talents, starting with initial efforts in rural areas in Nigeria and Kenya.
            By collaborating with professionals and educators, we aim to offer comprehensive training and support that will enable these young people to thrive.
            We plan to expand our reach and impact across the continent and introduce technology to enhance the opportunities available to our participants.
          </p>
        </div>
      </div>

      {/* Section 2 */}
      <div className="flex flex-col xl:flex-row gap-8 items-center mt-16">
        {/* Text Section */}
        <div className="xl:w-1/2 text-center xl:text-left space-y-4">
          <p className="text-lg text-gray-700 leading-relaxed">
            PCDF primarily aligns with SDG4, Quality Education. This goal aims to ensure inclusive and equitable quality education and promote lifelong learning opportunities for all.
            Specifically, our initiative focuses on providing alternative educational opportunities, particularly for young people in rural areas who may not have access to traditional education or resources to develop their talents.
          </p>
          <p className="text-lg text-gray-700 leading-relaxed">
            Additionally, our initiative also supports SDGs 8, 10, and 17. Decent work and economic growth, by creating opportunities for young people to develop marketable skills, leading to potential employment or entrepreneurship in the arts.
            Reduced Inequalities, by targeting marginalized rural populations, ensuring they have access to education and opportunities to develop their talents, thereby reducing disparities in education and economic outcomes.
            Partnerships for the goals, as our strategy involves collaborations with local and international stakeholders, including professionals, educators, NGOs, and governments, to achieve its objectives.
          </p>
        </div>

        {/* Image Section */}
        <div className="xl:w-1/2">
          <img
            className="rounded-2xl w-full object-cover xl:h-[450px]"
            src={Ourstory2}
            alt={altText}
          />
        </div>
      </div>
    </section>
  );
};

export default Ourstory;