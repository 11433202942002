import React from 'react';
import Vission1 from "../images/pcdf-abt-2.png";
import Vission2 from "../images/pcdf-abt-02.png";
import Vission3 from "../images/pcdf-abt-03.png";

const Mission = () => {
  return (
    <div className="flex flex-col md:flex-row">
      {/* Vision Section */}
      <div className="md:w-1/3 bg-[#01234D] text-white flex flex-col items-center py-8 px-4 space-y-4">
        <img className="w-12 mb-4" src={Vission1} alt="Vision icon" />
        <h3 className="text-xl font-bold">Our Mission</h3>
        <p className="text-sm md:text-base leading-relaxed text-center">
          To identify, nurture, and educate young African talents by providing them with professional training and facilitating opportunities for them to showcase and monetize their talents.
        </p>
      </div>

      {/* Mission Section */}
      <div className="md:w-1/3 bg-[#FF0000] text-white flex flex-col items-center py-8 px-4 space-y-4">
        <img className="w-12 mb-4" src={Vission2} alt="Mission icon" />
        <h3 className="text-xl font-bold">Our Vision</h3>
        <p className="text-sm md:text-base leading-relaxed text-center">
          We envision an Africa where young people's talents are fully utilized, empowering them to thrive in a competitive global economy and become catalysts for sustainable social change within their communities.
        </p>
      </div>

      {/* Target Audience Section */}
      <div className="md:w-1/3 bg-black text-white flex flex-col items-center py-8 px-4 space-y-4">
        <img className="w-12 mb-4" src={Vission3} alt="Target audience icon" />
        <h3 className="text-xl font-bold">Target Audience</h3>
        <div className="text-sm md:text-base leading-relaxed text-center space-y-2">
          <h4 className="font-semibold underline">Primary Audience</h4>
          <p>
            Talented young people in rural areas of Africa, especially those with limited access to formal education and resources.
          </p>
          <h4 className="font-semibold underline">Secondary Audience</h4>
          <p>
            Educators, local artists, community leaders, sponsors, and collectors who will support and promote these young talents.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Mission;