import React from 'react';
import { FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="text-center text-white bg-blue-600">
      {/* Grid container */}
      <div className="container p-4 pb-0">
        {/* Section: Links */}
        <div className="flex flex-wrap justify-center">
          {/* Grid column */}
          <div className="w-full md:w-1/3 lg:w-1/4 px-4 mt-3">
            <h6 className="text-uppercase mb-4 font-bold">
              Prince Child Development Foundation
            </h6>
            <p>
            PCDF Programs and projects have been designed as a catalyst for the discovery, development and deployment of various talents in marginalized communities to make them global forces and agents of social change in their communities.
            </p>
          </div>
          {/* Grid column */}

          {/* Grid column */}
          <div className="w-full md:w-1/4 lg:w-1/6 px-4 mt-3">
            <h6 className="text-uppercase mb-4 font-bold">Quick Links:</h6>
            <p>
              <a
                className="text-white bg-red-500 rounded px-2 py-1 inline-block"
                href="https://flutterwave.com/donate/l8kv0ba31gpk"
                target="_blank"
                rel="noopener noreferrer"
              >
                Donate
              </a>
            </p>
            <p>
              <a className="text-white" href="/about">
                About us
              </a>
            </p>
            <p>
              <a className="text-white" href="/project">
                Projects
              </a>
            </p>
            <p>
              <a
                className="text-white"
                href="https://forms.gle/gpQFfYQeaFQz7sUe9"
                target="_blank"
                rel="noopener noreferrer"
              >
                Volunteer
              </a>
            </p>
          </div>
          {/* Grid column */}

          {/* Grid column */}
          <div className="w-full md:w-1/3 lg:w-1/4 px-4 mt-3">
            <h6 className="text-uppercase mb-4 font-bold">Contact</h6>
            <p>Africa</p>
            <p>admin@pcdfafrica.org</p>
            <p>+2349094640954</p>
          </div>
          {/* Grid column */}

          {/* Grid column */}
          <div className="w-full md:w-1/4 lg:w-1/6 px-4 mt-3">
            <h6 className="text-uppercase mb-4 font-bold">Follow us</h6>
            <div className="flex justify-center space-x-3">
              {/* Facebook */}
              <a
                className="text-white bg-blue-800 p-2 rounded-full"
                href="https://www.facebook.com/PrinceChildDevelopmentConsult"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF />
              </a>
              {/* Instagram */}
              <a
                className="text-white bg-pink-600 p-2 rounded-full"
                href="https://www.instagram.com/pcdf2023"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
              {/* LinkedIn */}
              <a
                className="text-white bg-blue-500 p-2 rounded-full"
                href="https://www.linkedin.com/company/pcdf2023"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Copyright */}
      <div className="text-center p-3 bg-black bg-opacity-20">
        <p>&copy; {new Date().getFullYear()} Prince Child Development Foundation</p>
      </div>
    </footer>
  );
};

export default Footer;

