import React, { useState } from 'react';
import Member1 from "../images/pcdf-team-1.jpg";
import Member2 from "../images/pcdf-team-2.jpg";
import Member3 from "../images/pcdf-team-3.jpg";
import Member4 from "../images/pcdf-team-4.jpg";
import Member5 from "../images/pcdf-team-5.jpg";
import Member6 from "../images/pcdf-team-6.jpg";
import Member7 from "../images/pcdf-team-7.jpg";
import Member8 from "../images/pcdf-team-8.jpg";
import Member9 from "../images/pcdf-team-9.jpg";

// Example bios for team members (update with actual bios you sent)
const teamMembers = [
  {
    name: "Prince Orji",
    role: "Founder",
    image: Member1,
    bio: "Prince Orji is an impact catalyst committed to activating the abilities in young people and channeling it for social development and societal transformation. As the founder of Prince Child Development Foundation, he drives initiatives that empower African youths in rural communities...",
    linkedin: "https://www.linkedin.com/iamprinceorji"
  },
  {
    name: "Adeniyi Glory Oluwasunmisola",
    role: "Head of Marketing and Communications",
    image: Member2,
    bio: "Meet Adeniyi Glory Oluwasunmisola, a creative and charismatic content creator and social media manager with a passion for storytelling. As the Head of Marketing and Communication at Prince Child Development Foundation...",
  },
  {
    name: "Melard Onyago Ager",
    role: "Head of Programs",
    image: Member3,
    bio: "Melard Onyago Ager is a multifaceted professional with expertise in education, project/program management, human resources, and information technology. He volunteers as the Head of Programs at Prince Child Development Foundation...",
  },
  {
    name: "Summer Chiamaka Anyanwu",
    role: "Head of Fundraising",
    image: Member4,
    bio: "Summer Chiamaka Anyanwu is a dedicated fundraiser at the Prince Child Development Foundation. She plays a pivotal role in driving the organization’s mission to support and empower African children...",
    linkedin: "https://www.linkedin.com/in/summer-anyanwu"
  },
  {
    name: "Abel Kehinde Miracle",
    role: "Finance Officer",
    image: Member5,
    bio: "Abel Kehinde Miracle is a dedicated and passionate youth development enthusiast, driven by a mandate to empower teenagers and young adults. As a committed member and Finance Officer of Prince Child Development Foundation...",
  },
  {
    name: "Paul Precious Chialuka",
    role: "Legal Officer",
    image: Member6,
    bio: "Paul Precious Chialuka is a seasoned legal professional known for her diligence and competence. As the Legal Officer at Prince Child Development Foundation, she ensures the organization's operations adhere to legal frameworks...",
  },
  {
    name: "Olugboye Mogboluwaga Ayomide",
    role: "Admin Officer",
    image: Member7,
    bio: "Olugboye Mogboluwaga Ayomide is currently pursuing a degree in Human Nutrition and Dietetics at the University of Ibadan. She serves as the Administrative Officer at Prince Child Development Foundation...",
  },
  {
    name: "Ampem Darkowaa Sarfo",
    role: "Head of People and Operations",
    image: Member8,
    bio: "Ampem Darkowaa Sarfo is a distinguished educationist and passionate advocate for the empowerment of the girl child, boasting 19 years of experience in teaching and school management...",
    linkedin: "https://www.linkedin.com/in/ampem-darkowaa-sarfo-291492a4"
  },
  {
    name: "Nyanjom Barbra",
    role: "Human Resource Officer",
    image: Member9,
    bio: "Nyanjom Barbra is a dedicated and results-driven customer service expert with a passion for delivering exceptional experiences. Currently serving as a Human Resource Officer at Prince Child Development Foundation...",
  },
];

const Team = () => {
  const [selectedMember, setSelectedMember] = useState(null);

  const handleProfileClick = (member) => {
    setSelectedMember(member);
  };

  const handleCloseBio = () => {
    setSelectedMember(null);
  };

  return (
    <div className='bg-gradient-to-r from-blue-600 to-red-600 text-white flex flex-col items-center pb-10'>
      <h2 className='font-bold text-4xl py-5'>Meet The Team</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {teamMembers.map((member, index) => (
          <div
            key={index}
            className="w-64 hover:scale-105 transition-transform duration-300 ease-in-out overflow-hidden flex flex-col items-center"
            onClick={() => handleProfileClick(member)}
          >
            <img
              className='w-full h-64 object-cover rounded-xl shadow-lg'
              style={{ objectPosition: 'center top' }} // Ensures uniform framing
              src={member.image}
              alt={member.name}
            />
            <div className="flex flex-col text-center p-3">
              <span className='font-semibold text-lg'>{member.name}</span>
              <span className='text-lg'>{member.role}</span>
            </div>
          </div>
        ))}
      </div>

      {selectedMember && (
  <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center">
    <div className="bg-white text-black p-6 rounded-xl w-11/12 md:w-1/2 lg:w-1/3">
      <h3 className="font-bold text-2xl">{selectedMember.name}</h3>
      <p className="text-lg">{selectedMember.role}</p>
      <p className="mt-4">{selectedMember.bio}</p>
      {selectedMember.linkedin && (
        <a href={selectedMember.linkedin} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
          LinkedIn Profile
        </a>
      )}
      <button
        className="mt-4 bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700"
        onClick={handleCloseBio}
      >
        Close
      </button>
    </div>
  </div>
)}

    </div>
  );
};

export default Team;
