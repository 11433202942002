import React from 'react';

const Ourvalue = () => {
  return (
    <div className="p-5 md:p-10 bg-gray-100">
      {/* Header Section */}
      <div className="text-center md:px-48 py-8">
        <h2 className="font-bold text-4xl text-[#01234D] py-4 font-serif">Our Values</h2>
        <p className="text-gray-700">
          We are committed to guiding our initiatives with values that drive meaningful impact and foster growth for young talents.
        </p>
      </div>
      
      {/* Values Grid */}
      <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-5">
        <div className="bg-white shadow-md rounded-lg p-6 hover:shadow-lg transition-shadow">
          <h3 className="font-bold text-xl text-[#FF0000] mb-2">Integrity</h3>
          <p className="text-gray-600">Upholding honesty, transparency, and accountability in all our actions.</p>
        </div>
        
        <div className="bg-white shadow-md rounded-lg p-6 hover:shadow-lg transition-shadow">
          <h3 className="font-bold text-xl text-[#FF0000] mb-2">Innovation</h3>
          <p className="text-gray-600">Encouraging creativity and forward-thinking in education and other fields.</p>
        </div>
        
        <div className="bg-white shadow-md rounded-lg p-6 hover:shadow-lg transition-shadow">
          <h3 className="font-bold text-xl text-[#FF0000] mb-2">Community</h3>
          <p className="text-gray-600">Fostering collaboration and support within a strong network of young talents, mentors, and stakeholders.</p>
        </div>
        
        <div className="bg-white shadow-md rounded-lg p-6 hover:shadow-lg transition-shadow">
          <h3 className="font-bold text-xl text-[#FF0000] mb-2">Empowerment</h3>
          <p className="text-gray-600">Providing young people with the tools, knowledge, and resources to succeed in their chosen fields, transforming their lives and communities.</p>
        </div>
        
        <div className="bg-white shadow-md rounded-lg p-6 hover:shadow-lg transition-shadow">
          <h3 className="font-bold text-xl text-[#FF0000] mb-2">Excellence</h3>
          <p className="text-gray-600">Striving for the highest standards in all our endeavors.</p>
        </div>
      </div>
    </div>
  );
}

export default Ourvalue;
