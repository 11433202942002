import React from 'react';
import HeroSection from '../components/HeroSection';
import slide1 from '../images/slide1.jpg';
import slide2 from '../images/slide2.jpg';
import slide3 from '../images/slide3.jpg';
import slide4 from '../images/slide3.jpg';
import project from '../images/project.jpg'

const projects = [
  {
    title: 'Project 1',
    description: 'Providing clean water to underprivileged communities.',
    image: slide1
  },
  {
    title: 'Project 2',
    description: 'Educational support for children in rural areas.',
    image: slide2 
  },
  {
    title: 'Project 3',
    description: 'Healthcare assistance for vulnerable populations.',
    image: slide3 
  },
  {
    title: 'Project 4',
    description: 'Environmental sustainability initiatives.',
    image: slide4 
  },
];

function Projects({ children }) {
  return (
    <>
      <div>
        <HeroSection title="Projects"
        backgroundImage={project} />
      </div>
      <main className="container mx-auto py-10 px-4">
        <section className="text-center">
          <h1 className="text-4xl font-bold mb-6 md:text-5xl">Our Projects</h1>
          <p className="text-2xl mb-8">
          We help discover and develop talents in Young Africans.
          </p>
        </section>

        <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.map((project, index) => (
            <div key={index} className="p-6 border rounded-md shadow-md hover:shadow-lg transition-shadow">
              <img src={project.image} alt={project.title} className="mb-4 rounded" /> 
              <h2 className="text-2xl font-bold mb-2">{project.title}</h2>
              <p>{project.description}</p>
            </div>
          ))}
        </section>
        {children} 
      </main>
    </>
  );
}

export { projects };
export default Projects;
