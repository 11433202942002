import React from 'react';

function HeroSection({ title, backgroundImage }) {
  return (
    <div 
      className="hero-section text-center py-20 relative" 
      style={{
        backgroundImage: `url(${backgroundImage})`, 
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        opacity: 0.9, 
      }}
    >
      <div className="absolute inset-0 bg-white opacity-70"></div> 
      <h1 className="text-4xl font-bold md:text-5xl relative z-10">{title}</h1>
    </div>
  );
}

export default HeroSection;

